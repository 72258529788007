
.dorsal-virtual {

    &__wrapper {
        margin: 1rem;
    }

    &__preview {
        max-width: 100%;
    }

    &__link {
        display: block;
        padding: 1rem;
        border: 1px solid var(--color-gray-darker);
        // background: lighten(@gray-darker, 50);
        background: var(--color-gray-light);
        margin-bottom: 3rem;
    }

    &__btn {

    }
}
