
.virtual-prueba {

   &__item {
    //position: relative;
    /*
    --aspect-ratio: 16/9;
    overflow: hidden;
    */
  }

  &__sizer {
    height: 0;
    padding-top: 133%;
    // @todo: este color non está nunha variable!
    background-color: #eee;
    position: relative;
    margin-bottom: 1.6em;
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: .8em;
  }

  &__tiempo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // @todo: este color non está nunha variable!
    background: rgba(0, 0, 0, .6);
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding: .5em .8em ;

    font-weight: 600;
    color: white;
    font-size: 1.5em;
    white-space: nowrap;

    &--seleccionado {
    // @todo: este color non está nunha variable!
      color: #ff886c; //@brand-primary;
    }

    &--extendido {
        height: 28%;
    }

    p {
        margin-bottom: .1rem;
    }
  }

    &__icono-verificacion {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: .8em;
    }
}

.resultados-infovirtual__icon {
	width: 50px;
	height: 50px;
	float: left;
}

// @todo: este tamaño non está nunha variable!
@media (max-width: 767px) {
    .datos-usuario .column {
        width: 100%;
    }

    .resultados-infovirtual__item {
        font-size:0.9em;
    }
    .resultados-infovirtual__icon {
        width: 40px;
        height: 40px;
    }
}
