@brand-light: #DDEEEE;
@brand-primary: #888888;
@brand-secondary: #444444;
@brand-primary-oposite: #ffffff;
@brand-secondary-oposite: #ffffff;
@brand-light: #DDEEEE;

@font-family-destacado: Helvetica, Arial, sans-serif;

@text-color: #333333;
@footer-backcolor: #333333;
@gray-darker: #545454;
@rojo-destacado: #dc1648;
@rojo-tenue: #f26664;

@size-sm: 576px;
@size-md: 768px;
@size-lg: 992px;
@size-xl: 1200px;



body.recorrido_detail {
  padding-top: unset;
}

/** Clases de uso general **/

table td a {
    border-bottom: 1px dashed #1e9db2;
}

table td a:hover {
    color: #1e9db2;
    text-decoration: none;
}

.hand-mouse-pointer {
  cursor: pointer;
}

.brand-color {
  color: @brand-primary;
}

.background-brandcolor {
  background-color: @brand-primary;
  color: @brand-primary-oposite;
}

.background-brandcolor > a,
.background-brandcolor > a:hover {
  color: @brand-primary-oposite;
}

.background-brandcolor-sec {
  background-color: @brand-secondary;
  color: @brand-secondary-oposite;
}

.background-brandcolor-sec > a,
.background-brandcolor-sec > a:hover {
  color: @brand-secondary-oposite;
}

.background-brandcolor {
  background-color: @brand-primary;
  color: @brand-primary-oposite;
}

.background-brandcolor-oposite {
  background-color: @brand-primary-oposite;
  color: @brand-primary;
}

.background-brandcolor-light {
  background-color: lighten(@brand-primary, 35%);
}

.navbar-toggle {
  cursor: pointer;
}

[type=reset], [type=submit], button, html [type=button] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.icono-toggle::before {
  font-family: fontawesome;
  content: "\f107";
}

.collapsed .icono-toggle::before {
  content: "\f105";
}

.contenedor {
  padding: 1em 9vw 1em;
}

.container-fullframe {
  width: 60vw;
  margin: 0 auto;
}

.container-fullframe-90 {
  width: 90vw;
  margin: 0 auto;
}

.rojo-destacado {
  color: #dd1111;
}

.no-padding, .recorridos .row .no-padding {
  padding: 0 !important;
}

h1 ul,
h2 ul,
h3 ul,
h4 ul {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 0.9em;
}

h1 a, h1 a:hover,
h2 a, h2 a:hover {
  color: @brand-secondary-oposite;
}

h1 li:before,
h2 li:before,
h3 li:before {
  content: "::";
  padding-right: 0.7em;
  font-weight: bold;
}

h1 li:first-child:before,
h2 li:first-child:before,
h3 li:first-child:before {
  content: unset;
  padding-right: unset;
}

@media (max-width: 991px) {
  .contenedor {
    padding: 0.5em 4vw 0.5em;
  }

  .container-fullframe {
    width: 81vw;
  }
}

/** Encabezados **/

.page-header-web {
  background: transparent url("../images/fondo-titular.png") repeat 0 0;
  border-bottom: 0;
}

.page-header-web > h1,
.page-header-web > h2 {
  text-transform: uppercase;
  background-color: #333333;
  display: table;
  padding: 0.7em 2em;
  color: #FFFFFF;
  margin: 2em 0;
  font-size: 1.1rem;
  font-weight: 400;
}

.carreras .nav-link {
  padding: unset;
  display: unset;
}

/** /Encabezados **/

/** Pagination **/

.page-item.active .page-link {
  background-color: @brand-secondary;
  border-color: @brand-secondary;
}

/** /Pagination **/

/** NavBar General **/

.navbar a:hover {
  text-decoration: none;
}

nav.navbar#mainNav .dropdown-menu li a::after {
  content: none;
}

/** /NavBar General **/

/** Navbar2 **/

#mainNav2 {
    line-height: 1.1em;
}

#mainNav2 ul.dropdown-menu {
  padding: 0;
  bottom: auto;
  background-color: #333333;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

#mainNav2 ul.dropdown-menu {
  background-color: #333333;
}

#mainNav2 ul.dropdown-menu a {
  color: #FFFFFF;
}

#mainNav2 .dropdown-menu {
  border: none;
  width: max-content;
  width: -moz-max-content;
  min-width: 12.5rem;
}

#mainNav2 .dropdown-menu li.nav-item {
  padding: 0.3em 0;
  border-top: 1px dotted fade(#000000, 35%);
}

#mainNav2 .dropdown .dropdown-menu {
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;

  max-height: 0;
  display: block;
  overflow: hidden;
}

#mainNav2 .dropdown.show .dropdown-menu,
#mainNav2 .dropdown.open .dropdown-menu //la clase .dropdown.open es para bootstrap 3, cuando no quede ninguna PGD con bt3 puede borrarse esta linea dejando solo la de .dropdown.show
{
  max-height: 500px;
  opacity: 1;
}

#mainNav2 #idiomas {
    margin-bottom: 8px;
}

#mainNav2 #idiomas li {
  cursor: pointer;
  padding-left: .4rem;
  padding-right: .4rem;
}

#mainNav2 #idiomas li button {
  cursor: pointer;
}

#mainNav2 .nav-link {
  padding: 0 1em;
}

#mainNav2 li:last-child .nav-link {
    border-right: none;
}

#mainNav2 .navbar-toggler .fa-bars::before {
    line-height: 1.4em;
    background-color: #FFF;
    padding: 6px;
    border-radius: 3px;
    color: #444444;
}

@media (min-width: 992px) {

  #mainNav2 ul.dropdown-menu {
    box-shadow: 1px 4px 7px -2px rgba(0, 0, 0, .15);
    margin-top: 5px;
  }

  #mainNav2 .dropdown-menu li.nav-item:first-child {
    border-top: none;
  }

}

@media (max-width: 1199px) {

  #mainNav2 .dropdown-menu li.nav-item:hover {
    background-color: fade(#000000, 40%);
  }

  #mainNav2 .dropdown-menu li.nav-item {
    border-bottom: 1px dotted fade(#FFFFFF, 35%);
    background-color: fade(#000000, 15%);
  }

  #mainNav2 .dropdown-menu li.nav-item:last-child {
    border-bottom: none;
  }

  #mainNav2 .dropdown-menu {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }

  #mainNav2 ul.dropdown-menu {
    padding: 0;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
  }

  #mainNav2 ul.dropdown-menu li a {
    padding: 0.3em;
  }
}

@media (max-width: 768px) {
  #mainNav2 #idiomas li {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

/** /Navbar2 **/

/** /Clases de uso general  **/

/** Efectos para los logos del pie **/

.grises {
  filter: grayscale(100%);
  // -webkit-transition: filter 0.5s; /* Safari */
  transition: filter 0.5s;
}

.grisesclaros {
    filter: brightness(0) invert(1);
  // filter: grayscale(100%) brightness(120%);
  // -webkit-filter: grayscale(100%) brightness(120%);
  transition: filter 0.5s;
  // -webkit-transition: filter 0.5s; /* Safari */
}

.brillo {
    filter: brightness(0) invert(1);
  // filter: grayscale(100%) brightness(240%);
  // -webkit-filter: grayscale(100%) brightness(240%);
  transition: filter 0.5s;
  // -webkit-transition: filter 0.5s; /* Safari */
}

.brillo.plus {
    filter: brightness(0) invert(1);
  // filter: grayscale(100%) brightness(470%);
  // -webkit-filter: grayscale(100%) brightness(470%);
}

.grises:hover, .brillo:hover, .grisesclaros:hover {
  filter: initial;
}

.logo-2c {
  min-height: 105px;
  position: relative;
}

.container-crop {
  overflow: hidden;
  position: absolute;
}

.container-crop .crop {
  margin: 0 -180px 0 0;
  filter: grayscale(100%);
  transition: filter 0.5s;
}

.container-crop .crop:hover {
  margin: 0 0px 0 -160px;
  filter: initial;
}

.container-crop .crop:hover {
  margin: 0 0px 0 -160px;
  transition: filter 0.9s;
}

.logos .row .col-12 {
  padding-top: 6px;
  padding-bottom: 6px;
}

footer .logos h4 {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 2.5em;
}

@media (min-width: 768px) {
  .container-crop {
    width: 160px;
  }

  footer .footer-below .col-xs-12 .logo-2c img {
    width: 320px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .logo-2c {
    min-height: 73px;
  }

  footer .footer-below .col-xs-12 .logo-2c img {
    width: 210px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .container-crop {
    width: 105px;
  }

  .container-crop .crop {
    margin: 0 -105px 0 0;
  }

  .container-crop .crop:hover {
    margin: 0 0px 0 -105px;
  }
}

@media (max-width: 575px) {
  .logo-2c {
    min-height: 125px;
    position: relative;
    width: 180px;
    overflow: hidden;
    margin: 8px auto;
    padding: 0;
  }

  footer .footer-below .col-xs-12 .logo-2c img {
    margin-top: 0;
    margin-bottom: 0;
  }

  .container-crop .crop {
    margin: 0 -180px 0 0;
  }

  .container-crop .crop:hover {
    margin: 0 0px 0 -180px;
  }
}

/** /Efectos para los logos del pie **/

/** Listado de galerias de imagenes **/

@media (max-width: 767px) {
  .galeria-lista h3 {
    margin-top: 0;
    padding: 0 1em;
  }

  .galeria-lista img {
    width: 100%;
    max-height: 33vh;
    min-height: 280px;
    object-fit: cover;
  }

  .galeria-imagen-principal {
    padding: 2em;
  }

  .galeria-titulo {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .galeria-lista .row.noticia-resumen {
    padding: 1.2em 0em 2em 0em;
  }

  .galeria-lista img {
    object-fit: cover;
    min-width: 100%;
    min-height: 190px;
    max-height: 300px;
  }

  .galeria-titulo {
    padding-left: 1.8em;
  }
}

@media (min-width: 1920px) {
  .galeria-lista img {
    min-height: 12vw;
    height: 12vw;
  }
}

@media (min-width: 3500px) {
  .galeria-lista img {
    min-height: 7vw;
    height: 7vw;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .bootstrap-4-1 .galeria-lista .row.noticia-resumen {
    padding: 1.2em 0em 2em 0em;
  }

  .bootstrap-4-1 .galeria-lista img {
    object-fit: cover;
    min-width: 100%;
    min-height: 22vw;
  }

  .bootstrap-4-1 .galeria-imagen-principal {
    padding: 1em 0px;
  }

  .bootstrap-4-1 .galeria-titulo {
    text-align: left;
    padding-top: 1.4em;
    padding-left: 1.5em;
  }

  .bootstrap-4-1 .galeria-titulo h3 {
    padding-left: 0;
    font-size: 1.7rem;
  }

  .bootstrap-4-1 .galeria-lista .fecha {
    font-size: 0.7rem;
    color: #777;
  }
}

/** /Listado de galerias de imagenes **/

/** Detalle de galerias de imagenes **/

@media (max-width: 767px) {
  .galeria img {
    width: 25vw;
    height: 25vw;
  }
}

/** /Detalle de galerias de imagenes **/

/** Imagenes de Noticias **/

#noticias .imagen-header img {
  width: 100%;
  object-fit: cover;
  max-height: 36vh;
  min-height: 270px;
}

#noticias .imagen-footer img {
  width: 100%;
  height: 18vw;
  object-fit: cover;
  margin: 1.1em 0;
}

#noticias .imagen-body img {
  float: left;
  padding: 1em 1.8em 1em 1em;
  object-fit: cover;
  width: 40%;
}

@media (max-width: 767px) {
  #noticias .imagen-body img {
    float: none;
    padding: 0;
    width: 100%;
  }
}

/** /Imagenes de Noticias **/

/** Listado de Resultados de ediciones anteriores **/

#portfolio .portfolio-item {
  margin: 0 0 15px;
  right: 0;
}

#portfolio .portfolio-item .portfolio-link {
  display: block;
  position: relative;
  max-width: 250px;
  margin: 0 auto;
  max-height: 250px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  min-height: 16vw;
  padding: 0;
}

#portfolio .portfolio-item .portfolio-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#portfolio .portfolio-item .portfolio-link .caption {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
}

#portfolio .portfolio-item .portfolio-link .caption:hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content {
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: 20px;
  text-align: center;
  top: 40%;
  margin-top: -30%;
  padding: 0 1rem;
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content .edicion-nombre {
  font-size: 0.8em;
  line-height: 1em;
  margin-top: .5rem;
  text-transform: uppercase;
}

#portfolio .cartel {
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
}

#portfolio .year {
  font-size: 1.4em;
  margin-bottom: 0.5em;
  display: block;
}

@media (max-width: 767px) {
  #portfolio .year {
    font-size: 2em;
    font-weight: bold;
  }
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content i {
  margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content h3,
#portfolio .portfolio-item .portfolio-link .caption .caption-content h4 {
  margin: 0;
}

#portfolio * {
  z-index: 2;
}

#portfolio .portfolio-item .portfolio-link {
  background-color: #555555;
}

#portfolio .portfolio-item .portfolio-link .caption {
  background-color: fade(#555555, 70%);
}

#portfolio .portfolio-item .portfolio-link .caption:hover {
  background-color: fade(#555555, 90%);
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content {
  color: #FFFFFF;
}

#portfolio .sin-cartel {
  background-color: #555555;
}

.button-year .lista-botones a {
  text-align: left;
  padding: 7% 10% 0;
  color: #FFF;
  line-height: 1em;
  width: 100%;
  height: 100%;
  font-size: 15px;
  /* background-color: #555555; */
}

@media (max-width: 1024px) {
  .button-year .lista-botones a {
    font-size: 1.5vw;
  }
}

@media (max-width: 575px) {
  .button-year .lista-botones a {
    font-size: 3.4vw;
  }
}

.button-year .lista-botones a:hover,
.button-year .lista-botones a:active {
  background-color: #333333;
  text-decoration: none;
  -webkit-transition: background-color 0.5s; /* Safari */
  transition: background-color 0.5s;
}

@media (max-width: 767px) {
  #portfolio .portfolio-item .portfolio-link .caption {
    opacity: 1;
  }

  .ediciones-lista .no-cartel .texto {
    font-size: 1em;
  }

  .ediciones-lista .no-cartel {
    padding-top: 0.5em;
  }

  .ediciones-lista .fa, .ediciones-lista .edicion-nombre {
    display: none;
  }

  #portfolio-item .year {
    font-size: 3em;
  }

  #portfolio .portfolio-item .portfolio-link .caption {
    opacity: 1;
  }

  #portfolio .portfolio-item .portfolio-link .caption {
    background: rgba(62, 71, 84, .5);
  }
}

@media (max-width: 575px) {
  #portfolio .portfolio-item .portfolio-link .caption .caption-content {
    margin-top: -0.9em;
  }

  #portfolio .year {
    font-size: 1.4rem;
    margin-right: 0.5em;
    display: inline;
  }

  #portfolio .fa {
    font-size: 1.6rem;
  }
}

@media (min-width: 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}

@media (min-width: 1024px) {
  #portfolio .portfolio-item .portfolio-link {
    min-height: 250px;
  }
}

@media (max-width: 767px) {
  #portfolio .portfolio-item .portfolio-link .caption {
    opacity: 0.5;
  }
}

ul.seleccion-edicion {
  line-height: 1.1em;
  padding: 0;
}

/** /Listado de Resultados de ediciones anteriores **/

.seleccion-categorias ul {
  width: 100%;
  padding: 0;
}

.boton-categorias {
  list-style: none;
  float: left;
}

.boton-categorias a {
  background-color: #dddddd;
  padding: 1em;
  margin: 0.8em 0;
  height: 9.2em;
  width: 100%;
  line-height: 1.2em;
  text-transform: uppercase;
  text-align: center;
  display: table;
}

.sin-cartel {
  background-color: #888888;
  padding: 1em;
  text-align: center;
  color: #FFF;
  font-size: 0.8em;
}

.sin-cartel .year {
  font-size: 2.8em;
}

.sin-cartel .edicion-nombre {
  line-height: 1.4em;
}

@media (max-width: 767px) {
  .sin-cartel .year, .sin-cartel .edicion-nombre, #portfolio .sin-cartel .year {
    display: none;
  }
}

/** Ultima hora **/

.ultima-hora {
  background-color: #eeeeee;
  color: #555555;
}

.ultima-hora .container {
  margin-top: 0;
  padding: 0.5rem 1.6rem;
}

.ultima-hora .fecha {
  color: #d5140f;
}

/** /Ultima hora **/

/** Recorridos **/

.recorrido_detail {
  background-color: @gray-darker;
}

.recorrido_detail .contenido {
  padding: unset;
}

.recorridos .row {
  margin: 0px;
}

.recorrido_detail .recorridos {
  /* position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh; */
}

.recorrido_detail #mainNav,
.recorrido_detail #mainNav2,
.recorrido_detail .btn-compartir,
.recorrido_detail header,
.recorrido_detail footer {
  display: none;
}

.recorrido_detail h2 {
  margin: 0.1em 1em;
  font-size: 1em;
  text-align: center;
}

.recorridos a.menu-item {
  background-color: #000000;
  display: block;
  padding: 0.5em 1em;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.50);
  font-size: 0.9em;
}

.recorridos a.menu-item.trayecto {
  background-color: #e4e7e6;
  color: #333333;
}

.recorridos a.menu-item.trayecto:hover {
  background-color: #b0b0b0;
}

.recorridos ul.nav-tabs {
  padding-left: 5px;
  padding-right: 5px;
}

.recorridos .list-group {
  margin-bottom: 0;
}

.recorridos .btn-primary {
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.2em;
}

.recorridos ul.map-info {
  list-style-type: none;
  text-transform: uppercase;
  margin: 0;
  color: #FFF;
  padding: 0.2em 1em 0;
  line-height: 0.9em;
  width: 100%;
  bottom: 0px;
  left: 0px;
  font-size: 14px;
}

.recorridos ul.map-info li {
  display: inline-block;
  border-right: 1px solid #fff;
  margin-right: 1em;
  padding-right: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.recorridos ul.map-info li:last-child {
  border-right: none;
}

.infowindow {
  text-align: center;
}

.recorridos .panel {
  margin-bottom: unset;
}

.recorridos .navbar-toggle {
  float: unset;
}

.recorridos .panelControl {
  background-color: #213e45;
  display: block;
  border: 1px solid white;
}

.recorridos .panelDerecho {
  width: 52px;
  padding-left: 6px;
  padding-right: 5px;
  border-right: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-top: 1vh;
  z-index: 16 !important;
}

.recorridos .panelInferior {
  width: auto;
  border-radius: 6px;
  height: 60px;
  z-index: 1000010 !important;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.movil .recorridos .panelInferior {
  margin-bottom: 77px;
}

.recorridos .boton-mapa {
  color: white;
  cursor: pointer;
  text-align: center;
  display: block;
  font-family: "Barlow Semi Condensed", arial, sans-serif;
  font-size: 9px;
  line-height: 1.1em;
  text-transform: uppercase;
}

.recorridos .panelDerecho > div {
  border-bottom: 1px solid white;
  padding: 7px 1px;
}

.recorridos .panelDerecho > div:last-child {
  border-bottom: none;
}

.recorridos .panelInferior > div {
  border-right: 1px solid #fff;
  float: left;
  width: 55px;
  margin: 7px 0;
  height: 45px;
}

.recorridos .panelInferior > div#listaCompeticiones {
  background-color: rgba(#000, 0.6);
  margin: 0;
  height: 100%;
  padding-top: 7px;
  border-right: none;
  /* border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; */
}

.recorridos .panelControl.panelInferior > div#listaCompeticiones .boton-mapa {
  color: #ffffff;
}

.recorridos .panelInferior > div:last-child {
  border-right: none;
}

.recorridos .boton-mapa .fa {
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 3px;
}

.recorridos div.desplegable {
  overflow: auto;
  border: 1px solid #fff;
  border-radius: 6px;
  background-color: #222222;
  font-family: "Barlow Semi Condensed", arial, sans-serif;
  font-size: 11px;
  margin: 0 auto;
  position: absolute;
}

.recorridos div.desplegable-inferior {
  top: unset !important;
  left: calc(50vw - 117px) !important;
  max-height: 52vh;
  bottom: -3000px !important;
  width: 235px;
  transition: bottom 0.9s;
  transition-timing-function: ease-in;
  padding-bottom: 5px;
}

.recorridos div.desplegable-superior {
  bottom: unset !important;
  left: calc(50vw - 40%) !important;
  max-height: 70vh;
  top: -3000px !important;
  width: 80%;
  font-size: 1.4em;
  transition: top 0.9s;
  transition-timing-function: ease-in;
  background-color: #333333;
  margin-top: -4px;
  border: none;
}

.recorridos .desplegable-derecho {
  position: fixed !important;
  top: 145px !important;
  right: -2000px !important;
  left: unset !important;
  max-height: 301px;
  transition: right 0.9s;
  transition-timing-function: ease-in;
  padding-right: 5px;
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 159px;
}

.recorridos #desplegable-recorrido {
  max-width: 80vw;
  flex-direction: column;
}

.recorridos #desplegable-recorrido div:first-child {
  padding-top: 6px;
  background-color: black;
}

.desplegable-derecho .boton-mapa {
  width: 50px;
  height: 50px;
  border: 1px dotted #7d8085;
  float: left;
  padding: 4px 1px 1px 1px;
}

.recorridos .boton-mapa .fa-stack {
  font-size: 1.15em;
}

.recorridos .desplegable-derecho.mostrar {
  right: 46px !important;
  transition: right 0.5s;
  transition-timing-function: ease-out;
}

.recorridos div.desplegable-superior.mostrar {
  top: 0px !important;
  z-index: 16 !important;
  transition: top 0.5s;
  transition-timing-function: ease-out;
}

.recorridos div.desplegable-inferior a.menu-item {
  padding: 6px 1em;
}

.recorridos div.desplegable-inferior > div:last-child a.menu-item {
  border-bottom: none;
}

.recorridos div.desplegable-inferior.mostrar {
  bottom: 66px !important;
  z-index: 16 !important;
  transition: bottom 0.5s;
  transition-timing-function: ease-out;
}

.recorridos div.desplegable-inferior a {
  text-overflow: ellipsis; /* will make [...] at the end */
  width: 100%; /* change to your preferences */
  white-space: nowrap; /* paragraph to one line */
  overflow: hidden;
  background-color: unset;
}

.recorridos div.desplegable-inferior a:hover {
  background-color: darken(@brand-primary, 12%);
}

.recorridos .desplegable-caret {
  height: 11.8px;
  display: flex;
  padding: 0;
  margin: 2px 0 0;
  font-size: 0.7em;
  width: 100%;
  justify-content: center;
  background-color: #333;
}

.recorridos .map-lineas-tr .desplegable-caret .fa {
  bottom: 3px;
}

.recorridos div#desplegable-esquema.desplegable-superior {
  bottom: unset !important;
  left: unset !important;
  position: relative;
  max-width: 500px;
}

.recorridos #desplegable-esquema {
  background-color: #000;
  color: #fff;
  padding: 12px 1em 1em;
  font-size: 0.8em;
  margin: 28px auto 0 auto;
  z-index: 7 !important;
  border: none;
}

.recorridos #desplegable-esquema a {
  color: #ffffff;
}

.recorridos #desplegable-esquema a:hover,
.recorridos #desplegable-esquema a:focus,
.recorridos #desplegable-esquema a:active {
  text-decoration: none;
}

.recorridos #desplegable-esquema .distancia-trayecto,
.recorridos #desplegable-esquema .repeticiones-d {
  float: right;
  padding-left: 0.2em;
}

.recorridos #desplegable-esquema .distancia-trayecto {
  width: 65px;
  text-align: right;
}

.recorridos #desplegable-esquema .distancia-total .distancia-trayecto {
  width: 125px;
}

.recorridos #desplegable-esquema .icono,
.recorridos #desplegable-esquema .repeticiones {
  width: 12px;
  display: inline-block;
  margin-right: .2em;
}

.recorridos #desplegable-esquema .distancia-total.total {
  border-top: 1px solid #fff;
  margin-top: 1.5em;
  margin-bottom: 5px;
  padding-top: 0.1em;
  font-weight: 700;
  font-size: 1.1em;
}

.recorridos #desplegable-esquema .distancia-total.sector {
  border-bottom: 1px dotted #777;
  margin-top: 0.6em;
  margin-bottom: 5px;
  padding-top: .2em;
}

.recorridos #desplegable-esquema .distancia-total .nombre-trayecto {
  text-transform: uppercase;
}

.recorridos #desplegable-esquema .distancia-total .icono {
  margin-right: 0.5em;
}

.recorridos #desplegable-esquema .distancia-total.bicicleta,
.recorridos #desplegable-esquema .distancia-total.ciclismo {
  border-bottom-color: #FAAC58;
  color: #FAAC58;
}

.recorridos #desplegable-esquema .distancia-total.carrera,
.recorridos #desplegable-esquema .distancia-total.running {
  border-bottom-color: #FE2E2E;
  color: #FE2E2E;
}

.recorridos #desplegable-esquema .distancia-total.natación,
.recorridos #desplegable-esquema .distancia-total.nadar {
  border-bottom-color: #2ECCFA;
  color: #2ECCFA;
}

.recorridos #desplegable-esquema .nombre-trayecto {
  text-overflow: ellipsis;
  width: 29vw;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  line-height: .8em;
  margin-bottom: -.2em;
  padding-bottom: .2em;
}

.recorridos #desplegable-esquema .distancia-total .nombre-trayecto {
  max-width: 150px;
}

.recorridos #desplegable-esquema .esquema-masinfo {
  padding: 1.5em;
}

.recorridos #desplegable-esquema .esquema-masinfo .label {
  font-weight: bold;
  font-size: 1em;
}

.recorridos #rec-map,
.recorridos #map-area #rec-map {
  /* width: 100vw;
   height: 92.5vh;
   border: none;
   z-index: 15; */
  height: 100%;
}

.recorrido_detail .map-plano {
  font-weight: 700;
}

.recorrido_detail .recorridos div.desplegable-inferior a.menu-item.map-trayecto {
  padding-left: 2.5em;
  background-color: rgba(#000, 0.1);
  cursor: pointer;
}

.recorrido_detail .recorridos div.desplegable-inferior a.menu-item.map-trayecto:hover {
  background-color: rgba(#000, 0.9);
  color: #FFFFFF;
}

.recorrido_detail .recorridos .desplegable .fa {
  margin-right: 0.5em;
}

.recorrido_detail .map-header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.recorrido_detail .map-header h2 {
  font-size: 1.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.recorrido_detail .map-header .button-bars {
  position: absolute;
  right: 14px;
  top: 10px;
}

.recorridos .map-lineas-tr {
  position: relative;
  top: 33px;
  color: #f0f8ff;
  text-align: center;
  background-color: #000;
  z-index: 15;
  font-size: 0.9em;
}

.recorridos .map-header:hover,
.recorridos .map-lineas-tr span:hover {
  cursor: pointer;
}

.recorridos .map-lineas-tr a:hover {
  text-decoration: none;
}

.recorridos .map-lineas-tr .fa {
  font-size: 1.5em;
  bottom: -4px;
  position: relative;
  padding: 0 0.2em 0 0.8em;
}

.recorridos .map-lineas-tr .repeticiones {
  font-weight: bold;
}

.recorridos .map-lineas-tr .nombre-trayecto {
  text-overflow: ellipsis;
  max-width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  line-height: 0.8em;
  margin-bottom: -0.2em;
  padding-bottom: 0.2em;
}

.recorridos .chart-container {
  position: relative;
  margin: auto;
  height: 27vw;
  width: 80vw;
  min-height: 200px;
}

@media (max-width: 639px) {
  .recorrido_detail .map-header h2 {
    font-size: 1.2em;
    max-width: 77%;
  }

  .recorridos .map-lineas-tr .distancia-trayecto,
  .recorridos .map-lineas-tr .nombre-trayecto {
    display: none;
  }
}


/*** Recorridos full-screen ***/

body.recorrido_detail {
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: "Barlow Semi Condensed", arial, sans-serif;
}

.recorrido_detail .map-header {
  height: 41px;
  background: black;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: white;
  overflow: hidden;
  z-index: 15;
}

.recorrido_detail .map-footer {
  height: 20px;
  background: @brand-primary;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: none; //desactivar si se quiere poner algo en el pie
}

.recorrido_detail .map-container {
  background: gray;
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0px;
  /* bottom: 20px; //activar si se quiere poner algo en el pie */
}

/*** /Recorridos full-screen ***/

@media (min-width: 1024px) {
  .recorrido_detail h2 {
    font-size: 2vw;
  }

  .recorridos ul.map-info {
    display: block;
  }
}

@media (min-width: 1400px) {

  .recorrido_detail h2 {
    font-size: 1.4vw;
  }

  .recorridos .panelInferior {
    height: 4.5vw;
    border-radius: 0.3vw;
    margin-bottom: 0.5vw;
  }

  .recorridos .panelInferior > div {
    width: 4.4vw;
    height: 3.2vw;
    margin: 0.6vw 0;
  }

  .recorridos .panelDerecho {
    width: 4vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
  }

  .recorridos .boton-mapa {
    font-size: 0.7vw;
    min-height: 3.05vw;
  }

  .recorridos .panelInferior > div#listaCompeticiones {
    padding-top: 0.6vw;
  }

  .recorridos div.desplegable-inferior,
  .recorridos div.desplegable-derecho,
  .recorridos div.desplegable-derecho a.menu-item {
    max-width: 12.5vw;
    font-size: 0.7vw;
  }

  .desplegable-derecho .boton-mapa {
    padding: 0.4vw 1px 1px 1px;
  }

  .recorridos div.desplegable-inferior {
    left: calc(50vw - 8.5vw) !important;
  }

  .recorridos div.desplegable-inferior a {
    width: 100%;
  }

  .recorridos div.desplegable-inferior.mostrar {
    bottom: 4.76vw !important;
  }

  .recorridos .desplegable-derecho {
    max-width: 13vw;
  }

  .recorridos .desplegable-derecho .boton-mapa {
    width: 4vw;
    height: 4vw;
  }

  .recorridos .desplegable-derecho.mostrar {
    right: 3.6vw !important;
  }

  .recorridos div#desplegable-recorrido.desplegable-derecho {
    flex-wrap: nowrap;
  }

  .recorridos div#desplegable-recorrido a.menu-item {
    text-align: center;
  }

  .recorridos #desplegable-recorrido {
    max-width: 1000px;
  }

  .recorridos div.desplegable-superior {
    left: calc(50vw - 500px) !important;
  }

  .recorridos div.desplegable-derecho a.menu-item.map-plano {
    font-size: 0.8vw;
  }
}

@media (max-width: 779px) {
  .recorridos ul.map-info {
    font-size: 1.9vw;
  }
}

.recorridos-lista .nombre {
  padding-top: 6px;
}

.recorridos-lista ul {
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
}

/** /Recorridos **/

/** PDIs Recorridos **/

pdis-label {
  color: #00b3ee;
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: #14B2CB;
  padding: 5px 4px;
  border-radius: 2px;
  font-family: "Barlow Semi Condensed";
  font-size: 1.1em;
  text-transform: uppercase;
  width: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 36px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.7);
  color: #ffffff;
  cursor: pointer;
  transition: height .3s, width .3s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.popup-bubble.mostrar.peque {
  height: 100px !important;
  justify-content: center;
}

.popup-bubble.mostrar {
  width: 230px;
  height: 228px;
  transition: height .3s, width .3s;
  transition-timing-function: ease-in;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: /* TIP_HEIGHT= */ 22px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: /* TIP_HEIGHT= */ 22px solid #14B2CB;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  text-align: center;
  /* The max width of the info window. */
  width: 200px;
}

.popup-bubble .foto,
.popup-bubble .titulo,
.popup-bubble .descripcion,
.popup-bubble .boton {
  display: none;
}

.popup-bubble .descripcion {
  font-size: .7em;
  margin: .4em .1em;
  padding: 0 1em;
  max-height: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup-bubble a.boton {
  background-color: rgba(255, 255, 255, .6);
  border-radius: 3px;
  padding: 4px;
  color: #222;
  font-size: 0.8em;
  margin: 0.5em 0.9em;
}

.popup-bubble a:hover {
  text-decoration: none;
}

.popup-bubble .titulo {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 1px 0 0 0;
  max-width: 170px;
  max-height: 1.1em;
}

.popup-bubble .icono {
  transition: font-size 0.4s;
}

.popup-bubble.mostrar .icono {
  font-size: 1.4em;
  margin: 0 0.2em;
  transition: font-size 0.4s;
}

.popup-bubble.mostrar .titulo {
  display: inline-block;
}

.popup-bubble.mostrar .foto,
.popup-bubble.mostrar .descripcion,
.popup-bubble.mostrar .boton {
  display: block;
}

.popup-bubble.mostrar .foto {
  display: block;
  width: 100%;
  height: 130px;
  object-fit: cover;
  margin-bottom: 4px;
}

/** /PDIs Recorridos **/

/** Estilos para modulo de recorridos **/

/*** Descripcion y perfil ***/

.recorridos .infospl,
.recorridos #descripcion {
  position: fixed;
  z-index: 20;
  top: -105vh;
  right: 5vw;
  left: 5vw;
  overflow: auto;
  padding: 1vw 4vw;
  background-color: rgba(255, 255, 255, 0.95);
  transition: top ease-in 0.3s;
  max-height: 70vh;
}

.recorridos #descripcion {
  height: 80vh;
}

.recorridos #descripcion h3 {
  margin-top: 10px;
  font-size: 24px;
}

.recorridos .cerrar-ventana {
  right: 4vw;
  position: absolute;
  cursor: pointer;
}

.recorridos .infospl.mostrar,
.recorridos #descripcion.mostrar {
  top: 5vw;
  transition: top ease-out 0.7s;
}

.recorridos #descripcion.rec-descripcion p {
  font-size: 1.3em;
}

/*** /Descripcion ***/

.recorridos .rec-descripcion {
  padding: 1vw 4vw;
  font-family: @font-family-destacado;
}

.recorridos .infowindow .nombre {
  background-color: @brand-primary;
  color: @brand-primary-oposite;
}

.recorridos #pdimarkers-evento,
.recorridos #pdimarkers-evento-generales {
  font-size: 1.2em;
}


@keyframes transparent-pulse {
  0% {
    background-color: @brand-secondary;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: @brand-secondary;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/** /Estilos para modulo de recorridos **/

/** Horarios en detalle de evento **/

.infocarrera ul {
  padding: 0;
}

.infocarrera ul li {
  display: table;
  clear: both;
  margin: .5em 0;
}

.infocarrera .hora {
  background-color: #333333;
  float: left;
  padding: .4em .8em;
  display: block;
  color: #fff;
  font-weight: 700;
  margin-right: .7em;
  width: 4.5em;
  text-align: center;
  margin-bottom: .7em;
}

@media (min-width: 767px) {
  .infocarrera .horarios ul {
    max-height: 400px;
    overflow: auto;
  }
}

/** /Horarios en detalle de evento **/

/** Botones detalle evento **/

.box {
  padding: 8px;
  text-transform: uppercase;
  text-align: center;
  background-color: #555555;
}

.box:before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.box .content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  text-align: center;
  padding: 10px 2px;
  line-height: 1.1em;
  overflow: hidden;
}

.infocarrera ul.lista-botones {
  /* margin: 0 auto; */
  width: 100%;
  display: table;
  font-size: 14px;
  list-style: none;
  padding-left: 0;
}

.infocarrera ul {
  padding: 0;
}

.infocarrera ul li {
  margin: 0.5em 0;
}

.infocarrera ul.lista-botones li {
  margin-right: 3%;
  margin-top: 3%;
  clear: none;
  float: left;
  display: table;
  background-color: @brand-primary;
}

.infocarrera .lista-botones a {
  display: table;
  text-align: center;
  font-family: 'barlow semi condensed', sans-serif;
  text-transform: uppercase;
  padding-top: 7%;
  height: 100%;
}

.infocarrera ul.lista-botones a .texto-uppercase {
  margin-top: 4%;
  display: inline-block;
}

.infocarrera ul.lista-botones a .texto {
  color: #fff;
}

.infocarrera .nombre-competicion {
}

@media (max-width: 640px) {
  .infocarrera .box a {
    font-size: 2.5vw;
  }
}

/** /Botones detalle evento **/

/** Tandas en detalle de evento **/

.tandas h3,
.equipos h3{
  margin-bottom: 0.8em;
  background-color: #dedede;
  padding: 4px 10px;
}

.tandas ul,
.equipos ul{
  padding-left: 0;
  list-style-type: none;
}

.tandas label,
.equipos label{
  font-weight: bold;
  margin-bottom: 0;
}

/** /Tandas en detalle de evento **/

/** Como llegar y Recorridos en detalle de evento **/

.como-llegar #map {
  min-height: 500px;
  max-height: 950px;
}

.como-llegar h4 {
  background-color: #333;
  color: #fff;
  margin: 0;
  padding: 0.3em 0.5em;
}

.como-llegar.row .col {
  padding: 0 !important;
}

.como-llegar .marker-link {
  padding: 0.5em 0.6em;
  background-color: #c9d1cb;
  color: #333333;
  cursor: pointer;
  width: 100%;
  display: table;
  border-top: 1px solid white;
  text-transform: uppercase;
}

.como-llegar .marker-link:hover {
  text-decoration: none;
  background-color: #333;
  color: #FFFFFF;
}

.como-llegar .marker-link .texto {
    pointer-events: none;
    line-height: 1em;
    display: block;
}

.como-llegar .marker-link object{
  pointer-events: none;
  float: left;
  margin-right: 0.6em;
}

.como-llegar .boton a {
  background-color: cadetblue;
  padding: 0.5em;
  display: block;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-top: 11px;
  font-size: 1.2em;
}

.evento-detail .como-llegar .list-group-item {
  line-height: 1.1em;
  text-transform: uppercase;
  font-weight: 300;
  border: 1px solid #ffffff;
  background-color: @brand-light;
}

#pdis .panel {
  border: none;
}

#pdis .panel > div {
  padding: 0;
}

/** /Como llegar y Recorridos en detalle de evento **/

/** Recorridos **/

.recorridos .rec-descripcion p {
  font-size: 1.3em;
}

.recorridos .infowindow .nombre {
  background-color: #555555;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.1em;
  padding: 5px 0;
}

/** /Recorridos **/

/** Botones Portada **/

ul.botones_portada {
  margin: 2em 0 1em;
  padding: 0;
}

ul.botones_portada .icon {
  font-size: 6vw;
  margin-bottom: 0.5vw;
}

ul.botones_portada {
  font-size: 3.5vw;
}

ul.botones_portada li {
  list-style: none;
  padding-right: 1vw;
  padding-left: 1vw;
  margin: 1vw 0;
}

ul.botones_portada a {
  background-color: #495b71;
  display: block;
  padding: 3vw 0;
  text-align: center;
}

ul.botones_portada li a:hover {
  background-color: #222222;
  text-decoration: none;
}

@media (min-width: 575px) {

  ul.botones_portada a {
    padding: 2.7vw 0;
  }

  ul.botones_portada {
    font-size: 2vw;
  }

  ul.botones_portada .icon {
    font-size: 4vw;
  }
}

@media (min-width: 992px) {
  ul.botones_portada {
    font-size: 1.1vw;
  }

  ul.botones_portada .icon {
    font-size: 2.2vw;
  }

  ul.botones_portada li {
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    margin: 0.5vw 0;
  }

  ul.botones_portada a {
    padding: 0.8vw 0;
  }

  ul.botones_portada .icon {
    margin-bottom: 0.3vw;
  }
}

ul.botones_portada a .texto {
  color: #fff;
  display: block;
  min-height: 2.2em;
  max-height: 2.2em;
  overflow: hidden;
  line-height: 1.1em;
  padding: 0 5px;
}

/** /Botones Portada **/

/** Anuncio app portada **/

.anuncio-portada .fa-mobile {
  font-size: 10em;
  margin: -.23em auto 0;
  display: table;
}

/** /Anuncio app portada **/

/** Prevent autozoom en iphone **/

input[type='text'],
input[type='number'],
input[type='password'],
.chosen-container-single .chosen-search input[type="text"],
select, select:focus,
textarea {
  font-size: 16px !important;
}

/** /Prevent autozoom en iphone **/

/** Botones de compartir "ShareThis" **/

#botones-compartir {
  position: fixed;
  top: -60vw;
  transition: top 0.5s;
  left: 0;
  width: 100%;
  z-index: 1040;
}

#botones-compartir.mostrar {
  top: 0;
  transition: top 0.5s;
}

.sharethis-inline-share-buttons {
  padding: 6vw;
  background-color: rgba(20, 20, 20, 0.8);
  width: 100%;
  z-index: 1035;
}

#st-1.sharethis-inline-share-buttons .st-btn {
  width: 13vw;
  height: 13vw;
  padding: 0;
  margin-right: 1.8vw;
}

#st-1.sharethis-inline-share-buttons .st-btn > svg {
  height: 8vw;
  width: 8vw;
  top: 2.4vw;
}

#botones-compartir #st-1 .st-btn > img {
  height: 7.5vw;
  width: 7.5vw;
  top: 2.8vw;
}

.scroll-top,
.btn-compartir {
  position: fixed;
  right: 2%;
  bottom: 15px;
  width: 50px;
  height: 50px;
  z-index: 1040;
}

.scroll-top {
  bottom: 65px;
  display: none;
}

.scroll-top .btn,
.btn-compartir .btn {
  font-size: 17px;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  line-height: 24px;
  border: 3px solid #FFF;
  padding: 4px 9px;
}

.scroll-top .btn:hover,
.btn-compartir .btn:hover,
.scroll-top .btn:active,
.btn-compartir .btn:active {
  border: 3px solid #fff;
}

.btn-compartir .btn {
  line-height: 27px;
}

@media (min-width: 992px) {
  #st-1.sharethis-inline-share-buttons .st-btn > svg {
    height: 3vw;
    width: 3vw;
    top: 1vw;
  }

  #st-1.sharethis-inline-share-buttons .st-btn {
    width: 5vw;
    height: 5vw;
    margin-right: .9vw;
  }

  #botones-compartir #st-1 .st-btn > img {
    height: 2.8vw;
    width: 2.8vw;
    top: 1vw;
  }
}

/** /Botones de compartir "ShareThis" **/

/** Cajas de botones de resultados **/

.boton-selecccion {
  border: 3px solid @brand-primary-oposite;
  padding: 0;
}

.boton-selecccion a {
  padding: 1em;
  min-height: 6.6em;
  display: block;
}

.boton-selecccion.active {
  background-color: @brand-secondary;
}

.boton-selecccion.active a {
  color: @brand-primary-oposite;
}

.boton-selecccion:hover a {
  background-color: fade(@brand-secondary, 40%);
  color: @brand-secondary-oposite;
}

.seleccion-categorias ul {
  width: 100%;
  padding: 0;
}

.boton-categorias {
  float: left;
}

.boton-categorias a {
  background-color: @brand-primary;
  padding: 1em;
  margin: 0.8em 0;
  height: 9.2em;
  width: 100%;
  line-height: 1.2em;
  text-transform: uppercase;
  text-align: center;
  display: table;
  font-size: 0.9em;
}

.boton-categorias.active a {
  background-color: @brand-secondary;
  color: @brand-secondary-oposite;
}

.boton-categorias:hover a {
  background-color: @brand-primary;
  color: @brand-primary-oposite;
}

.ediciones-lista .no-cartel {
  background-color: #999999;
  height: 100%;
  text-align: center;
  padding-top: 1.5em;
}

.ediciones-lista .no-cartel .texto {
  font-size: 2em;
  color: @brand-primary-oposite;
  line-height: 1.1em;
}

/** /Cajas de botones de resultados **/

/** Footer **/

footer {
  color: white;
  font-family: @font-family-destacado;
}

footer h3 {
  margin-bottom: 30px;
}

footer .footer-above {
  padding-top: 20px;
  background-color: @brand-primary;
  margin-top: 50px;
}

footer .footer-col {
  margin-bottom: 50px;
}

footer .footer-below {
  padding: 25px 0;
  background-color: @footer-backcolor;
}

footer .footer-below a img {
  margin: 0em auto;
  display: table;
}

footer .footer-below-the-below {
  background-color: @footer-backcolor;
}

footer li a:hover {
  text-decoration: none;
  color: @brand-secondary-oposite;
}

footer .redes-sociales li {
  width: -moz-fit-content;
  float: left;
  margin: 0.4em;
  padding: 0;
}

footer .redes-sociales .btn-social {
    font-size: 28px;
    margin-top: 0;
    border: none;
    padding: 5px;
}

footer .copyright a {
  color: @rojo-tenue;
}

@media (min-width: 992px) {
  footer .navbar-nav {
    flex-direction: row;
  }

  footer .redes-sociales li {
    float: right;
    margin: 7px 0.4rem;
  }

  footer .panel {
    padding: 0 0.3em;
  }

  footer .panel ul {
    list-style-type: none;
    padding: 0 0.4em;
    font-size: 0.8em;
  }

  footer .collapse {
    display: table;
    visibility: visible;
  }

  footer .caret {
    display: none;
  }

  footer .navbar-nav > li > a {
    padding-bottom: 4px;
    margin: 0 0.5em 0.7em 0.5em;
    font-weight: bold;
    font-size: 1.1em;
  }

  footer ul li img {
    display: none;
  }
}

@media (max-width: 991px) {
  footer .panel {
    border-bottom: 1px solid @brand-primary-oposite;
    border-radius: 0;
    margin-bottom: 0;
  }

  footer li.panel:last-child {
    border-bottom: none;
  }

  footer ul > li.panel > ul > li.panel {
    border-bottom: none;
    list-style: none;
  }

  footer ul > li.panel > ul {
    margin: 0px;
    padding: 0.5em;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.1);
  }

  footer .bandera {
    border: 1px solid @brand-primary-oposite;
    margin-bottom: 6px;
  }

  footer .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
  }

  footer .container {
    width: 100%;
  }

  footer .footer-col {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  footer .footer-col.redes-sociales {
    margin-bottom: 0px;
    border-bottom: none;
  }

  footer .footer-tittle {
    text-align: center;
    margin: 2em 0;
  }
}

@media (max-width: 767px) {
  footer .footer-below .col-xs-12 img {
    margin: 0.5em auto;
  }

  footer .footer-below .col-xs-12 .copyright {
    margin: 1em auto;
    width: 100%;
  }

  .redes-sociales .btn-outline {
    margin-top: 3px;
  }
}

@media (min-width: 768px) {
  .container-crop {
    width: 180px;
  }

  .container-crop .crop:hover {
    margin: 0 0px 0 -180px;
    transition: filter 0.9s;
  }

  footer .footer-below .col-xs-12 .logo-2c img {
    width: 360px;
  }
}

/** Footer **/


/** Flechas horizontales animadas **/

.wrapper a span {
  display: block;
  width: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0px;
  height: 16px;
  position: absolute;
}

.wrapper a span span {
  background: url(https://www.faktorzehn.org/de/wp-content/uploads/sites/2/2012/02/f10-org-new_3_6_0-arrowdown.gif);
  display: block;
  width: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0px;
  height: 16px;
  opacity: 1;
  position: absolute;
}

.wrapper a .arrow:before {
  content: "";
  background: url(https://www.faktorzehn.org/de/wp-content/uploads/sites/2/2012/02/f10-org-new_3_6_0-arrowdown.gif);
  display: block;
  width: 16px;
  top: -20px;
  bottom: 0px;
  margin: auto;
  right: 0px;
  height: 16px;
  position: absolute;
  opacity: 0.5;
}

.wrapper a .arrow:after {
  content: "";
  background: url(https://www.faktorzehn.org/de/wp-content/uploads/sites/2/2012/02/f10-org-new_3_6_0-arrowdown.gif);
  display: block;
  width: 16px;
  top: -40px;
  bottom: 0px;
  margin: auto;
  right: 0px;
  height: 16px;
  position: absolute;
  opacity: 0.3;
}

.wrapper a .arrow:before {
  animation: animate-arrow-2 1s ease-in-out infinite 0.1s;
}

.wrapper a .arrow:after {
  animation: animate-arrow-3 1s ease-in-out infinite 0.2s;
}

.wrapper a .arrow span {
  animation: animate-arrow-1 1s ease-in-out infinite;
}

@keyframes animate-arrow-1 {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  70%, 100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes animate-arrow-2 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  70%, 100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}

@keyframes animate-arrow-3 {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  70%, 100% {
    transform: translateY(0px);
    opacity: 0.3;
  }
}

/** /Botones de compartir "ShareThis" **/

/** Flechas horizontales animadas **/

.wrapper-arrow {
  bottom: 10px;
  right: 12px;
  position: absolute;
}

.wrapper-arrow > div span {
  display: block;
  width: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0px;
  height: 16px;
  position: relative;
}

.wrapper-arrow > div span span {
  background: url('../images/arrow-bottom.png');
  display: block;
  width: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0px;
  height: 8px;
  opacity: 1;
  position: absolute;
}

.wrapper-arrow > div .arrow:before {
  content: "";
  background: url('../images/arrow-bottom.png');
  display: block;
  width: 10px;
  top: -15px;
  bottom: 0px;
  margin: auto;
  right: 0px;
  height: 8px;
  position: absolute;
  opacity: 0.5;
}

.wrapper-arrow > div .arrow:after {
  content: "";
  background: url('../images/arrow-bottom.png');
  display: block;
  width: 10px;
  top: -30px;
  bottom: 0px;
  margin: auto;
  right: 0px;
  height: 8px;
  position: absolute;
  opacity: 0.3;
}

.wrapper-arrow > div .arrow span {
  animation: animate-arrow-1 1s ease-in-out infinite;
}

.wrapper-arrow > div .arrow:before {
  animation: animate-arrow-2 1s ease-in-out infinite 0.1s;
}

.wrapper-arrow > div .arrow:after {
  animation: animate-arrow-3 1s ease-in-out infinite 0.2s;
}

@keyframes animate-arrow-1 {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  70%, 100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes animate-arrow-2 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  70%, 100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}

@keyframes animate-arrow-3 {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  70%, 100% {
    transform: translateY(0px);
    opacity: 0.3;
  }
}

/** /Flechas horizontales animadas **/

/** Informacion adicional **/

.info-adicional .alert-info,
.info-adicional .alert {
  background-color: unset;
  padding: 0;
  border: unset;
  border-bottom: 1px dotted @brand-primary;
  color: @brand-primary;
}

.info-adicional .alert-danger {
  color: #da3545;
  border-bottom: unset;
}

.info-adicional .alert-danger::before {
  content: "\f071";
  color: #da3545;
  margin-right: 0.5em;
  font-size: 1.5em;
  font-family: FontAwesome;
  top: 5px;
  position: relative;
}

.info-adicional .h4, .info-adicional h4 {
  font-size: 1.1rem;
  font-weight: bold;
  border-bottom: 1px dotted #333;
  color: #333;
}

/** /Informacion adicional **/

/** Proximo evento portada **/

.proximo-evento-portada {
  background-color: fade(#ffffff, 60%);
  text-align: left;
  padding: 1em 1.5em;
  max-height: 46.7vh;
  overflow-y: auto;
  min-height: 27vw;
}

.proximo-evento-portada .label {
  color: @brand-secondary;
  font-weight: 700;
}

.proximo-evento-portada .nombre {
  color: @brand-primary;
  font-weight: 700;
  font-size: 1.4em;
  text-transform: uppercase;
  line-height: 0.8em;
  padding-right: 0.2em;
}

.proximo-evento-portada .fecha {
  color: @brand-primary;
}

.proximo-evento-portada .cuerpo {
  margin-top: 0.6em;
}

@media (min-width: 1600px) {
  .proximo-evento-portada {
    font-size: 1.2vw;
  }
}

/** /Proximo evento portada **/

/** PUBLICIDAD **/

.publicidad-horizontal img,
.publi-h img,
#noticias.noticias-lista .publi-h img {
  max-width: 100%;
  max-height: unset;
  width: auto;
  height: auto;
  padding: unset;
}

.publi-h .adsbygoogle,
.publicidad-horizontal img,
.publi-h img,
#noticias.noticias-lista .publi-h img {
  margin: 0em auto 3em;
}

.publicidad-vertical img,
.publi-v img,
#noticias.noticias-lista .publi-v img {
  position: absolute;
  width: 120px;
  height: 600px;
}

.publicidad-vertical.izq img,
.publi-v.izq img,
#noticias.noticias-lista.izq .publi-v img {
  left: calc(50vw - 610px);
}

.publicidad-vertical.dcha img,
.publi-v.dcha img,
#noticias.noticias-lista.dcha .publi-v img {
  right: calc(50vw - 610px);
}

#publicidad-popup img,
#noticias.noticias-lista #publicidad-popup img {
  height: unset;
  max-height: unset;
}

.publicidad-horizontal a,
.publi-h a {
  justify-content: center;
  display: flex;
}

.publi-v {
  margin-top: 2em;
}

.container.container-publi-v {
  max-width: 950px;
  min-height: 625px;
}

@media (max-width: 1190px) {
  .publicidad-vertical,
  .publi-v {
    display: none;
  }
}

@media (min-width: 1300px) {
  .publicidad-vertical.izq img,
  .publi-v.izq img,
  #noticias.noticias-lista.izq .publi-v img {
    left: calc(48vw - 610px);
  }

  .publicidad-vertical.dcha img,
  .publi-v.dcha img,
  #noticias.noticias-lista.dcha .publi-v img {
    right: calc(48vw - 610px);
  }
}

/** /PUBLICIDAD **/

/** NOVEDADES **/

#novedades h3 {
  margin-bottom: 1px;
}

#novedades .fecha {
  line-height: 0.7em;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 1em;
}

#novedades .page-header-web > h1, #novedades .page-header-web > h2 {
  margin: 3.5em 0 2.2em;
  font-size: 1.9rem;
}

#novedades.novedad-detalle .page-header-web > h2 {
  margin: 0;
}

#novedades.novedad-detalle .fecha {
  background-color: #DDDDDD;
  padding: 0.2em 1em 0.4em;
  width: 100%;
  color: #333;
}

#novedades .novedad-resumen, #novedades .novedad-contenido {
  padding: 0 7vw;
}

#novedades .novedad-resumen .novedad-item {
  border-bottom: 3px dotted #999;
  padding-bottom: 1.5em;
  margin-bottom: 1.5em;
}

#novedades .novedad-resumen .novedad-item:last-child {
  border-bottom: none;
}

/** /NOVEDADES **/

/** INSCRIPCIONES **/

#inscripcion iframe {
  margin: 35px 0;
}

/** /INSCRIPCIONES **/

/** BOTONES DE ENLACE CUADRADOS **/

/* Para usarlos hay que hacer una estructura como esta:

  <div class="col-sm-1 col-xs-6">
    <div class="square">
      <div>
        <div>
          <div>Test</div>
        </div>
      </div>
    </div>
  </div>

*/

.square {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;

  > div {
    height: 100%;
    position: absolute;
    width: 100%;

    > div {
      display: table;
      height: 100%;
      width: 100%;

      > a {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding: 0.5em;
      }
    }
  }
}

/** /BOTONES DE ENLACE CUADRADOS **/

/** BOTONES DE ENLACE CUADRADOS - AJUSTE RESPONSIVE DE TEXTO**/

@media (min-width: 768px) {
  .square {
    font-size: 1.2em;
  }
}

@media (min-width: 1600px) {
  .square {
    font-size: 1.6em;
    line-height: 1em;
  }
}

@media (max-width: 767px) {
  .seleccion .col {
    padding-right: 7px;
    padding-left: 7px;
  }
}

/** /BOTONES DE ENLACE CUADRADOS - AJUSTE RESPONSIVE DE TEXTO**/


/** RESULTADOS **/

.resultados .seleccion {
  text-transform: uppercase;
  line-height: 1.05em;
}

.resultados .seleccion a,
.resultados .seleccion a:hover {
  text-decoration: none;
}

.resultados .seleccion a.background-brandcolor-sec:hover {
  color: @brand-secondary-oposite;
}

.resultados td a {
  color: @gray-darker;
  font-weight: bold;
}

.resultados table td.nombre a{
    text-transform: uppercase;
}

.resultados label {
  margin-bottom: unset;
}

.resultados .seleccion a:hover {
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.resultados table td a {
  border-bottom: 1px dashed #1e9db2;
}

.resultados table td a:hover {
    text-decoration: none;
    color: #1e9db2;
}

.datos-usuario {
  line-height: 1.1em;
  text-transform: uppercase;
}

.datos-usuario ul {
  padding-left: 0;
}

.datos-usuario .nombre {
  font-size: 1.3em;
  font-weight: bold;
}

.datos-usuario .foto {
  border: 12px solid @brand-primary;
  text-align: center;
}

.datos-usuario .foto img {
  border: 1px solid #fff;
}

@media (max-width: 575px) {
  .datos-usuario {
    font-size: 0.85em;
  }

  .datos-usuario .foto {
    margin: 0em 25vw 10vw;
  }
}

@media (min-width: 992px) {
  .datos-usuario .foto {
    border: 15px solid @brand-primary;
    display: table-cell;
  }
}

/** /RESULTADOS **/

/** MENU USUARIO AREA PERSONAL **/

ul.dropdown-menu.dropdown-menu-usuario-color li {
  border-bottom: 1px dashed @brand-secondary-oposite;
  line-height: 1.1em;
}

ul.dropdown-menu.dropdown-menu-usuario-color li:last-child {
  border-bottom: none;
}

ul.dropdown-menu.dropdown-menu-usuario-color li a:hover {
  text-decoration: none;
  color: @rojo-tenue;
}

/** /MENU USUARIO AREA PERSONAL **/

/** VIDEOLLEGADA **/

.video-llegada {
  text-transform: uppercase;
  line-height: 1.1em;
  font-size: 0.9em;
}

.video-llegada .year {
  font-size: 1.5em;
  letter-spacing: -1px;
}


/** /VIDEOLLEGADA **/

/** APP BANNER **/

.app-banner {
  background-color: rgba(0, 0, 0, 0.8);
  line-height: 1.1em;
  color: #FFFFFF;
  position: fixed;
  top: 0px;
  z-index: 200;
}

.app-banner a {
  color: @brand-secondary-oposite;
}

/** /APP BANNER **/

/** Reloj de cuenta atras 'cuentatras' **/

#reloj {
  text-align: center;
}

#cuentatras.row {
  width: 90%;
  max-width: 310px;
  margin: 0 auto;
  padding: 0 0px;
  font-size: 0.75em;
}

#cuentatras.row .col-3 span {
  display: block;
  clear: both;
  line-height: 1em;
}

#cuentatras.row .col-3 {
  margin: 0;
  padding: 5px 2px 9px 2px;
  color: @brand-primary-oposite;
  text-transform: capitalize;
}

#cuentatras.row .col-3 > div {
  background-color: #222222;
  padding: 8px 2px;
}

#cuentatras.row .col-3 .cantidad {
  font-weight: bold;
  font-size: 3em;
}

@media (min-width: 1600px) {
  #cuentatras.row {
    font-size: 1em;
    max-width: 340px;
  }
  #cuentatras.row .col-3 > div {
    padding: 10px 2px;
  }
}

.cuenta-atras {
    &__icon {
        color: @brand-secondary;
    }
}

/** /Reloj de cuenta atras 'cuentatras' **/


/** Detalle de evento: Tramos de inscripcion **/

.tramos-inscripcion .competicion {
  border: 2px solid @brand-primary;
}

.tramos-inscripcion .competicion .nombre {
  line-height: 1.1em;
}

.tramos-inscripcion .competicion .boton-inscripcion {
  display: flex;
  justify-content: center;
}

.tramos-inscripcion .competicion .btn {
  background-color: lighten(@brand-primary, 30%);
  width: 100%;
  height: 68px;
  padding: .375rem .275rem;
}

.tramos-inscripcion .competicion .boton-inscripcion .btn {
  background-color: @rojo-destacado;
  font-size: 1.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tramos-inscripcion .competicion .caja-tramo {
  background-color: lighten(@brand-primary, 30%);
  min-width: 29%;
}

.tramos-inscripcion .competicion .caja-tramo .precio {
  font-size: 2.1em;
  letter-spacing: -0.03em;
  margin-top: -0.2em;
}

.tramos-inscripcion .competicion .caja-tramo .rango-temporal {
  line-height: 1em;
}

.tramos-inscripcion .competicion .limite-plazas {
  text-align: center;
}

.tramos-inscripcion .competicion .limite-plazas span {
  background-color: lighten(@rojo-destacado, 55%);
  color: @rojo-destacado;
}

@media (min-width: 768px) and (max-width: 991px) {
  .tramos-inscripcion .competicion .btn .texto {
    font-size: 0.7em;
  }

  .tramos-inscripcion .competicion .boton-inscripcion .btn {
    font-size: 1em;
  }
}

@media (max-width: 470px) {
  .tramos-inscripcion .competicion .btn .texto {
    font-size: 0.7em;
  }

  .tramos-inscripcion .competicion .boton-inscripcion .btn {
    font-size: 4vw;
  }
}

/** /Detalle de evento: Tramos de inscripcion **/


/** Politicas de privacidad y cookies **/


#politica-privacidad__fondo {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  padding: 2em;
  font-size: 0.9em;

  @media (max-width: 512px) {
    font-size: 2.8vw;
  }


  .container{
    max-width: 850px;
    background-color: white;
    border-radius: 10px;
    max-height: 70%;
    transition: max-height 0.5s ease;
    -moz-transition: max-height 0.5s ease;


    &.cerrado {
      max-height: 0%;
      opacity: 0;
    }

    p {
      @media (max-width: 512px) {
        margin-bottom: 1em;
      }
      a {
        @media (max-width: 512px) {
          color: #00b3ee;
          font-weight: bold;
        }
      }
    }

    h3 {
      font-size: 1.6em;
      margin-bottom: 0.5em;
      line-height: 1em;

      @media (max-width: 512px) {
        line-height: 1;
        margin-top: 1.4em;
      }
    }

    h5 {
      font-size: 1.25em;
    }
    .modal-cabecera h3 {
      margin-top: 1em;
    }
    .modal-contenido {
      max-height: 40vh;
      overflow: auto;
      border-top: 1px solid #bbb;
      padding-top: 1em;
      @media (max-width: 512px) {
        max-height: 30vh;
      }
    }
    .modal-pie {
      padding: 1.4em 0;
      border-top: 1px solid
      #bbb;
      @media (max-width: 512px) {
        padding: 1em 0;
      }
    }
    .boton_aceptacion {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .btn {
        @media (max-width: 512px) {
          font-size: 1.1em;
        }
      }
    }
  }

}


/** /Politicas de privacidad y cookies **/

/** Temas de ayuda **/

.ayuda h3 {
    color: #6b6b6b;
}

.ayuda ul li {
    list-style: none;
}

.ayuda .articulo {
    cursor: pointer;
}


/** /Temas de ayuda **/



/** Publicidad **/

.banner {
    margin: 0 auto;
    max-width: 100%;

    &__wrapper {
        padding: 15px;
    }
    &--desktop {
        display: none;
        @media screen and (min-width: @size-sm) {
            display: block;
        }
    }
    &--mobil {
        display: none;
        @media screen and (max-width: (@size-sm - 1px)) {
            display: block;
        }
    }
    &--frame {
        text-align: center;
        &> ins {
            margin: 0 auto;
        }
    }
}

// PGDS con menú NON flotante que teñen un marxe inferior o padding.
.variante--header-margin-bottom {
    .banner {
        margin-top: -4em;
    }
}

.variante--body-padding-top.no-portada {
    .banner__wrapper {
        margin-top: -51px;
    }
}
.variante--body-padding-top.portada {
    .banner--mobil {
        margin-top: 20px;
    }
}

/** /Publicidad **/

/** Descarga de documentos - usuario final **/

.descarga-documentos ul{
    list-style: none;
    padding-left: 0;
}

.descarga-documentos li{
    list-style: none;
}

.descarga-documentos .icono::before {
    content: "\f15c";
    font-family: "Font Awesome 5 Free","Font Awesome 5 Brands", FontAwesome;
    font-style: normal;
    font-size: 1.6em;
}

.descarga-documentos .icono-clase-jpg::before,
.descarga-documentos .icono-clase-png::before,
.descarga-documentos .icono-clase-gif::before,
.descarga-documentos .icono-clase-bmp::before{
    content: "\f1c5";
}

.descarga-documentos .icono-clase-pdf::before{
    content: "\f1c1";
}

.descarga-documentos .icono-clase-doc::before{
    content: "\f1c2";
}

.descarga-documentos .icono-clase-mpg::before,
.descarga-documentos .icono-clase-mov::before,
.descarga-documentos .icono-clase-avi::before,
.descarga-documentos .icono-clase-mkv::before{
    content: "\f1c8";
}

.descarga-documentos li a{
    clear: both;
    display: flex;
    line-height: 1.6em;
    padding: 10px 20px;
    justify-content: space-between;
}

.descarga-documentos li a .descarga-documentos-izquierda{
    display: block;
    width: 78%;
}

.descarga-documentos li a .descarga-documentos-derecha{
    display: block;
    float: right;
    width: 100%;
    text-align: right;
}

.descarga-documentos .documento-nombre{
    max-width: 75%;
    line-height: 1.1em;
}

.descarga-documentos li:nth-child(even) {
    background-color: #eeeeee;
}

.descarga-documentos li a span {
    display: block;
    float: left;
    max-width: 45vw;
    overflow: hidden;
    text-overflow: ellipsis;
}

.descarga-documentos .fa-download,
.descarga-documentos .documento-archivo{
    float: right;
}

.descarga-documentos .fa-download {
    max-width: 19%;
}

.descarga-documentos .documento-archivo{
    width: 81%;
    text-align: right;
}

.descarga-documentos .fa-download {
    border-radius: 4px;
}

@media (max-width: 512px) {
    .descarga-documentos li {
        font-size: 3.2vw;
    }
}

/** /Descarga de documentos - usuario final **/


/** Puntos de recogida **/


.puntos-recogida h2 {
    font-size: 1.6em;
    margin-bottom: 11px;
}

.puntos-recogida ul{
    padding: 0;
    list-style: none;
}

.puntos-recogida ul li {
    margin-bottom: 1em;
}

.puntos-recogida .lista-pdis li .punto-nombre {
    background-color: @brand-secondary;
    color: @brand-secondary-oposite;
    font-weight: bold;
    padding: 4px 10px;
    cursor: pointer;
}

.puntos-recogida .lista-pdis li .punto-datos {
    padding: 10px;
}

.puntos-recogida .punto-direccion {
    line-height: 1em;
}

.puntos-recogida .btn {
    margin-top: 1em;
}

/** /Puntos de recogida **/

/** Video de portada **/

.portada footer .footer-above { /* esto evita que se vea el video de fondo entre el contenido y el footer*/
    margin-top: 0;
}

.contenedor-video {
    position: absolute;
    top: 42px;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -100;
    min-height: 50vh;
    max-height: 50vh;
    background-color: @brand-primary;
    background: url("../images/fondo-portada.jpg") top center no-repeat;
    background-size: cover;
}

.contenedor-video video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.dots-overlay {
    background-image: url("../images/dot.png");
    position: absolute;
    top: 42px;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -50;
    min-height: 50vh;
    max-height: 50vh;
    background-color: fade(@brand-secondary,10%);
}

@media (max-height: 700px) and (max-width: 400px) {
  /* header{
     min-height: 43vh;
  } */

  header .logo p {
      padding: 1em 0 0;
  }
}

@media (max-height: 600px) and (min-width: 480px) {

  header .logo p {
      font-size: 1.1em;
      padding: 1em 2em 0.2em;
  }
}

/** /Video de portada **/

/** Formularios **/

.floating-label-form-group {
    width: 100%;
}

.btn-info {
    background-color: @brand-secondary;
    border-color: @brand-secondary;
}

.form-inline label{
  margin-right: 0.6em;
}
/** /Formularios **/

/** Tablas **/

table .nombre a.text-danger {
  color: @brand-primary !important;
}

.table thead th {
    background-color: @brand-primary;
    color: @brand-primary-oposite;
}

.table thead th {
    border-bottom: 1px solid @brand-primary;
}

.table-bordered td, .table-bordered th {
  border: 1px solid fade(@brand-primary, 50%);
}

/** /Tablas **/

/** mainNav2 fijado arriba al hacer scroll - ver index.js **/

header {
    /* position: fixed; */
    z-index: 10;
    width: 100%;
    top: 0
}

header.noportada {
  position: fixed;
}

header.noportada + div,
header.noportada + section {
    margin-top: 100px;
}

.vista-app header.noportada + div,
.vista-app header.noportada + section {
    margin-top: 0;
}

header.noportada .logo-horizontal img {
    transition: all 0.5s;
}

header.noportada.menu-fijo .logo-horizontal img {
    max-width: 50px;
    max-height: 32px;
}

header.noportada.menu-fijo .logo-horizontal {
    padding: 0.1em 1em 0.2em 0;
}

header.noportada.menu-fijo #mainNav2.noportada ul.navbar-nav {
    transition: margin-top 0.5s;
    margin-top: 16px;
}

header.portada #mainNav{
  max-height: 50px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

header.portada.menu-fijo #mainNav{
    max-height: 0px;
    padding: 0;
}

@media (max-width: 768px) {
  .no-portada header #bs-example-navbar-collapse-2 {
    margin-top: 15px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .no-portada header.menu-fijo #bs-example-navbar-collapse-2 {
    margin-top: 0em;
  }
}

@media (max-width: 479px) {
    header.noportada.menu-fijo .logo-horizontal img {
        margin: 0 0;
        transition: margin 0.5s;
    }
}


/** /mainNav2 fijado arriba al hacer scroll - ver index.js **/

/** Logotipo en mainNav2 **/

#mainNav2 .logo a img {
    height: 65px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    // background-color: @brand-primary;
    border-radius: 0 0 5px 5px;
    padding: 7px 14px 0;
    margin-top: -7px;
}

@media (max-width: 768px) {
  #mainNav2 .logo a img {
    max-height: 50px;
  }
}

.menu-fijo #mainNav2 .logo a img {
  height: 30px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0;
  background-color: transparent;
}

.menu-fijo #idiomas {
    display: none;
}

@media (max-width: 1199px) {
    .navbar-nav#idiomas {
        flex-direction: row;
        justify-content: center;
        padding: 3px 0 10px 0;
    }
    #mainNav2.navbar button .texto,
    .navbar-nav#idiomas li.idioma.nav-item .texto{
        display: none;
    }
    #mainNav2 .nav li.idioma {
        border-top: none;
    }
    .portada .navbar-nav#idiomas {
        padding: 12px 0 10px;
    }
}

/** /Logotipo en mainNav2 **/

/** Header Nav Dropdown **/

#mainNav2 .navbar-toggler-icon {
  font-size: 4vw;
}

#mainNav2 .navbar-toggler .fa-bars::before{
  background-color: @brand-primary-oposite;
}

#mainNav2.navbar a{
  color: @brand-primary-oposite;
}

#mainNav2 ul.dropdown-menu a{
  color: @brand-primary-oposite;
}

#mainNav2 ul.dropdown-menu li:hover{
  background-color: @brand-secondary;
}

#mainNav2 ul.dropdown-menu li:last-child{
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

#mainNav2 ul.dropdown-menu li:hover a{
  color: @brand-secondary-oposite;
}

@media (max-width: 1199px) {
    #mainNav2 {
        text-align: center;
    }
    #mainNav2 .nav li {
      text-align: center;
    }
    #mainNav2 .nav-link {
        padding: 0.5em 0;
        border-right: none;
    }
    #mainNav2 #idiomas {
        padding-top: 6px;
    }
}


@media (max-width: 767px) {
    #mainNav2 .dropdown-menu {
        background-color: fade(@brand-secondary, 15%);
    }
    #mainNav2 .dropdown-menu {
        width: 100%;
        max-width: unset;
        min-width: unset;
    }
    #mainNav2 .nav li {
      border-top: 1px solid @brand-secondary-oposite;
    }
    #mainNav2 .dropdown-menu li.nav-item {
      border-top: 1px dotted @brand-secondary-oposite;
    }
}

#mainNav2 .navbar-toggle {
    padding: 7px 10px;
    margin-top: 3px;
    margin-bottom: 0px;
    color: white;
    background-color: @brand-secondary;
    text-transform: uppercase;
}

/** /Header Nav Dropdown **/

/** Detalles de usuario **/

.detalles-usuario .foto-usuario img,
.detalles-usuario img.img-usuario{
    width: 100%;
    border: 1px solid gray;
    padding: 1vw;
    margin-bottom: 1em;
}

.detalles-usuario .sharethis-inline-share-buttons {
  margin-bottom: 1em;
}

@media (max-width: 767px) {
  .detalles-usuario .foto-usuario {
    text-align: center;
  }
  .detalles-usuario .foto-usuario img {
      width: 60%;
      border: 1px solid gray;
      padding: 15px;
      margin-bottom: 1em;
  }
}

/** /Detalles de usuario **/


/** Estilos para modulo de galerias **/

.galeria .miniatura {
    padding: 1vw;
    width: unset;
}

.galeria img {
    object-fit: cover;
    width: 20vw;
    height: 20vw;
    max-height: 260px;
    padding: 0;
    margin: 0;
}

/** /Estilos para modulo de galerias **/

/** Informacion de eventos **/

.infocarrera ul.lista-botones a .texto,
.infocarrera ul .box a .texto
{
    color: @brand-primary-oposite;
}

.box {
   background: @brand-primary;
}

.infocarrera ul li {
    display: table;
    clear: both;
}

.infocarrera .hora {
    background-color: @brand-secondary;
    float: left;
    padding: .4em .8em;
    display: block;
    color: #fff;
    font-family: 'barlow semi condensed',sans-serif;
    font-weight: 700;
    margin-right: .7em;
    width: 4.5em;
    text-align: center;
    margin-bottom: 0.7em;
}

.evento-detail .list-group{
    margin-bottom: 20px;
}

.evento-detail .list-group-item {
    border: 1px solid @brand-secondary;
}
.evento-detail .nombre-competicion {
    background-color: @brand-secondary;
    color: #fff;
    text-transform: uppercase;
}

.evento-detail .nombre-competicion .badge {
    background-color: @brand-secondary;
    float: right;
}

@media (max-width: 575px) {
  .infocarrera ul {
    width: 66vw;
    margin: 0 auto;
  }
  .infocarrera ul li {
    margin: 0;
  }
  .content {
    font-size: 3.5vw;
  }
}

@media (min-width: 1601px) {
  .content {
    margin: 10px;
  }
}

/** /Informacion de eventos  **/

/** Ultima hora **/

.ultima-hora {
  font-family: @font-family-destacado;
  font-size: 1.5em;
  background-color: fade(@brand-primary,99%);
  color: @brand-primary-oposite;
  line-height: 1.05em;
}

/** /Ultima hora **/


/** Portada **/

.portada-titular h2{
  line-height: 0.95em;
  font-size: 4.2em;
  text-align: left;
}

.portada-titular p{
  line-height: 1.2em;
  font-size: 1.5em;
  text-align: justify;
}

.portada-titular.izq p{
  width: 17em;
  text-align: left;
}

body.portada .fondo-portada {
  background-color: rgba(199,220,214,1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 44vw;
}

#portada .logo {
  display: flex;
  justify-content: center;
  padding: 0 4%;
}

#portada .logo img{
    width: 26%;
    height: intrinsic; // No borrar - necesario para safari
}

@media (max-width: 767px) {
  body.portada .fondo-portada {
    background-position: bottom center;
    padding-bottom: 3vw;
    padding-top: 4vw;
  }

  #portada .logo {
    justify-content: center;
    width: 100%;
  }
  #portada .logo img{
    width: 70%;
  }
  .portada .navbar-nav#idiomas {
      padding: 12px 0 10px;
  }
}

@media (max-width: 479px) {
  #portada .logo img{
    width: 60%;
  }
}

@media (max-height: 479px) {
  #portada .logo img{
    max-width: 150px;
  }
}

body.portada #portada {
  margin-top: 70px;
}

body.portada.vista-app #portada {
    margin-top: 0;
}

@media (max-width: 991px) {
  #portada .texto-intro{
    font-size: 2.2vw;
    max-width: 350px;
    margin: 0 auto;
  }

@media (max-width: 767px) {
  .portada-titular h2 {
    font-size: 12vw;
  }

  .portada-titular p {
    font-size: 5vw;
  }
  #portada .texto-intro{
    font-size: 3vw;
  }
}

@media (max-width: 480px) {
  #portada .texto-intro{
    font-size: 5vw;
  }
}

}

/** /Portada titular **/

/** Twitter **/
.twitter-title {
  font-family: 'Barlow Semi Condensed',sans-serif;
  position: absolute;
  top: 1em;
  left: 1.3em;
  color: #fff;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 0.7em;
  /* text-shadow:  3px 3px 2px black; */
}

.twitter-title .subtexto{
  font-size: 2.6rem;
  font-weight: lighter;
}

.twitter-section img {
  max-width: 100%;
  height: 550px;
  object-fit: cover;
  border-radius: 8px;
  -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(80%);
}

#twitter-widget-0 {
  border-radius: 8px;
}

@media (max-width: 991px) {
  .twitter-section img {
    height: 105px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
  }
  .twitter-title {
    top: 1.4rem;
    left: 2.3rem;
    font-size: 2.5rem;
    text-shadow: 2px 2px #333;
  }
  .twitter-title .subtexto{
    font-size: 1.6rem;
  }
}



/** /Twitter **/


/** Noticias en portada **/

#portada .noticias img{
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

#portada .noticias .izq:hover img, #portada .noticias .dch .noticia-box:hover img{
  -webkit-filter: grayscale(10%);
  filter: grayscale(10%);
}

#portada .noticias .dch .noticia-box:hover {
  background-color: @brand-primary;
  color: @brand-primary-oposite;
  -webkit-transition: background-color .5s;
  transition: background-color .5s;
}

#portada .noticias .izq img{
  height: 430px;
}

#portada .noticias .dch .noticia-box{
  height: 100px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #111111;
  display: block;
  -webkit-transition: background-color .5s;
  transition: background-color .5s;
}

#portada .noticias .izq::after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, transparent 0%, #000000 110%);
  margin-top: -290px;
  height: 290px;
  width: 100%;
  content: '';
  border-radius: 5px;
}

#portada .noticias .texto-imagen {
  position: absolute;
  color: #fff;
  z-index: 10;
  bottom: 0px;
  padding: 2.5em;
  text-transform: uppercase;
  line-height: 1.4em;
  left: 0px;
  width: 100%;
}

#portada .noticias .texto-imagen .titulo{
    font-weight: bold;
    font-size: 1.4em;
    display: block;
}

#portada .noticias .texto-imagen .fecha{
    font-size: 1.2em;
}

#portada .noticias .noticia-box-texto-imagen {
  position: absolute;
  z-index: 10;
  text-align: left;
  padding: 0.7em 1em;
  color: #ffffff;
  line-height: 1.2rem;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  width: 92%;
}

#portada .noticias .noticia-box img{
  height: 100px;
  opacity: 0.65;
}

#portada .noticias .noticia-box-texto-imagen .fecha{
    font-weight: lighter;
    font-size: 1rem;
}

@media (min-width: 768px){
  #portada .noticias .centro .texto-noticia{
    max-height: 332px;
    overflow: hidden;
  }
}

@media (max-width: 992px) {
    #portada .noticia-box .titulo {
        max-height: 58px;
        overflow: hidden;
        display: inline-block;
    }
}

/** /Noticias en portada **/

/** Información Legal **/

.legal{
  text-align: justify;
}

.legal h3{
  text-align: center;
  margin: 3.5rem 0 2.5rem;
  color: @brand-secondary;
}

.legal h4{
  text-align: center;
  margin: 3rem auto 2rem;
  color: @brand-secondary;
  font-size: 1.3rem;
}

.legal a{
  color: #337ab7;
}

@media (min-width: 1200px) {
  .legal h4{
    max-width: 60%;
  }
}

/** /Información Legal **/


/** Paginacion **/

.pagination-navbar{
  display: flex;
}

.pagination {
    margin: 0 auto;
}

.pagination > li > a {
    padding: 0.5em 0.7em;
}

/** /Paginacion **/

/** Noticias **/

.imagen-body img {
    float: left;
    padding: 1em 1.8em 1em 1em;
}

#noticias h3{
    line-height: 1em;
    display: block;
    margin-bottom: 0.2em;
    font-family: @font-family-destacado;
}

#noticias.noticias-lista img {
  max-width: 100%;
  max-height: 170px;
  padding: 8px;
  width: auto;
  height: auto;
}

#noticias .fecha {
    font-size: 1.1rem;
    font-weight: 100;
    font-family: @font-family-destacado;
    line-height: 19px;
    color: @brand-primary;
}

/** /Noticias **/

.w3-animate-zoom {
    animation: animatezoom 0.6s;
}

/** Recorridos **/

body.recorrido_detail.no-portada {
    margin-top: 0;
}

.recorrido_detail .panelControl{
  background-color: @brand-primary;
}

.recorrido_detail .map-footer,
.recorrido_detail div.desplegable {
  background-color: @brand-primary;
}

.recorrido_detail .gm-svpc{     // Color de fondo y borde del macaquito de streetview
  background-color: @brand-primary !important;
  border: 1px solid @brand-primary-oposite;
}

/** /Recorridos **/

/** Botones Portada **/

ul.botones_portada a {
    background-color: @brand-primary;
}

ul.botones_portada li:hover a {
    background-color: @brand-secondary;
}

ul.botones_portada a .texto {
  color: @brand-primary-oposite;
}

ul.botones_portada a .texto,
ul.botones_portada .fa-inverse {
    color: @brand-secondary-oposite;
}
/** /Botones Portada **/


/** Notificaciones App **/

.notificacion .fecha {
    font-weight: bold;
}

/** /Notificaciones App **/

/** Descarga APP **/

.descarga-app h2{
    font-size: 3.5em;
}

.compartir__captura {
    width: 300px;
    height: auto;
    background-image: url('../images/movilfondo.png');
    background-repeat: round;
}

.compartir__captura > img {
    max-width: 100%;
    padding: 50px 20px;
}

@media (max-width: 992px) {
    .descarga-app {
        font-size: 2vw;
    }
    .descarga-app h2{
        font-size: 2em;
    }
    .compartir__captura {
        width: 100%;
    }
    .compartir__captura > img{
        padding: 17% 4%;
    }
}


@media (max-width: 767px) {
    .compartir__captura {
        height: 345px;
    }
}

@media (max-width: 575px) {
    .descarga-app {
        font-size: 4vw;
    }
    .compartir__captura {
        width: 272px;
        height: unset;
    }
}

/** /Descarga APP **/

/** Banner APP **/

.descarga-app .btn {
    width: 5em;
}
.base__banner-app {
    display: none;
}

.base__banner-app {
    position: fixed;
    top: 0px;
    background-color: rgba(0,0,0,0.7);
    z-index: 15;
    width: 100%;
    justify-content: space-between;
    color: #fff;
}

.base__banner-app a,
.base__banner-app a:hover{
    color: #fff;
    padding: 0.5em;
}

.base__banner-app a .fa {
    padding-top: 0.8em;
}

/** /Banner APP **/

@import "partes/dorsal-virtual.less";
@import "partes/actividades-virtuales";



